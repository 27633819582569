import { useHistory } from "react-router-dom";
import { Children, cloneElement } from "react";

/**
 * This component does not render any UI
 * It takes the child, and connects it to react-router
 * https://github.com/elastic/eui/blob/main/wiki/react-router.md#react-router-51
 */
export function BrainLink({ to, children, onClick: propsOnClick, ...rest }) {
  const history = useHistory();
  const child = Children.only(children);

  function onClick(event) {
    if (child.props.onClick) child.props.onClick(event);
    if (propsOnClick) propsOnClick(event);

    if (event.defaultPrevented) {
      return;
    }

    // Let the browser handle links that open new tabs/windows
    if (
      isModifiedEvent(event) ||
      !isLeftClickEvent(event) ||
      isTargetBlank(event)
    ) {
      return;
    }

    // Prevent regular link behavior, which causes a browser refresh.
    event.preventDefault();

    // Push the route to the history.
    history.push(to);
  }

  // Generate the correct link href (with basename accounted for)
  const href = history.createHref({ pathname: to });
  const props = { ...rest, href, onClick };

  return cloneElement(child, props);
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isTargetBlank(event) {
  const target = event.target.getAttribute("target");
  return target && target !== "_self";
}
