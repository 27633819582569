import React from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageContent,
} from "@elastic/eui";
import { BrainLink } from "../brain-link";

const ErrorBlock = () => {
  return (
    <EuiPageContent>
      <EuiFlexGroup
        responsive={false}
        justifyContent="spaceAround"
        alignItems="center"
        style={{ minHeight: "calc(100vh - 120px)" }}
      >
        <EuiFlexItem grow={false}>
          <EuiEmptyPrompt
            iconColor="danger"
            iconType="faceSad"
            titleSize="l"
            title={<h2>C'è stato un errore!</h2>}
            body={<p>Lo risolveremo il prima possibile</p>}
            actions={[
              <EuiButton
                color="primary"
                onClick={() => window.location.reload()}
                fill
              >
                Ricarica la pagina
              </EuiButton>,
              <BrainLink to="/">
                <EuiButtonEmpty color="subdued">Torna alla home</EuiButtonEmpty>
              </BrainLink>,
            ]}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPageContent>
  );
};

export default ErrorBlock;
