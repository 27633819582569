import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLoadingContent,
  EuiPage,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import config from "../../../config";
import Card from "./components/card";
import LoadingCard from "./components/loading-card";
import axios from "axios";
import moment from "moment";
import useAuth from "../../../hooks/auth";
import PublicApproveButton from "../components/approve-button";
import { BrainLink } from "../../../components/brain-link";
import spinupLogo from "../../../assets/images/logo.svg";

export default function CreativeGroupPage() {
  const { user } = useAuth();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { token } = queryString.parse(location.search);
  const [creativeGroup, setCreativeGroup] = useState();
  const [creativesLoading, setCreativesLoading] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);

  const init = async () => {
    if (creativesLoading) {
      return;
    }

    try {
      setCreativesLoading(true);

      let response;

      if (id) {
        response = await axios.get(
          `${config.backend}/api/public/creative-groups/${id}`,
        );
      } else {
        response = response = await axios.get(
          `${config.backend}/api/public/creative-groups`,
          {
            params: {
              token,
            },
          },
        );
      }

      setCreativeGroup(response.data.creative_group);

      setCreativesLoading(false);
    } catch (error) {
      setCreativesLoading(false);
    }
  };

  const getCreatives = () => _.get(creativeGroup, "creatives", []);

  const onChangeData = (items) => {
    const changedCreatives = _.map(getCreatives(), (c) => {
      if (_.find(items, (i) => i.id === c.id)) {
        const foundItem = _.find(items, (i) => i.id === c.id);

        let obj = { ...c };
        if (foundItem.status) {
          obj.status = foundItem.status;
        }

        if (foundItem.text) {
          obj.comments.unshift({
            created_at: moment(),
            text: foundItem.text,
          });
        }

        return obj;
      }
      return c;
    });

    setCreativeGroup({ ...creativeGroup, creatives: changedCreatives });
  };

  useEffect(() => {
    if (!token && !id) {
      history.push("/unauthorized");
    } else {
      init();
    }
  }, []);

  return (
    <EuiPage>
      <EuiFlexGroup direction="column" alignItems="center">
        <EuiSpacer />

        <EuiFlexItem grow={false} style={{ width: 320, alignItems: "center" }}>
          <EuiImage size="m" url={spinupLogo} alt="spinup" />
        </EuiFlexItem>

        <EuiSpacer size="s" />

        <EuiFlexItem grow={false} style={{ alignItems: "center" }}>
          <EuiTitle size="m">
            {creativesLoading ? (
              <div style={{ width: 100 }}>
                <EuiLoadingContent lines={1} />
              </div>
            ) : (
              <h1>{(creativeGroup && creativeGroup.name) || ""}</h1>
            )}
          </EuiTitle>
        </EuiFlexItem>

        <EuiSpacer />

        {creativeGroup ? (
          <EuiFlexGroup>
            {user ? (
              <EuiFlexItem>
                <BrainLink to={`/creative-groups/${creativeGroup?.id}/edit`}>
                  <EuiButton iconType="pencil">Edit</EuiButton>
                </BrainLink>
              </EuiFlexItem>
            ) : null}

            {creativeGroup.allow_public_approval ? (
              <EuiFlexItem>
                <PublicApproveButton
                  creativeGroupId={creativeGroup?.id}
                  creativeGroupStatus={creativeGroup?.status}
                />
              </EuiFlexItem>
            ) : null}
          </EuiFlexGroup>
        ) : null}

        <EuiSpacer size="s" />

        <div style={{ padding: 16, width: "100%", maxWidth: 1280 }}>
          {creativesLoading ? (
            <EuiFlexGrid columns={3} wrap>
              {_.map(_.times(6, String), (i, index) => (
                <EuiFlexItem key={index}>
                  <LoadingCard />
                </EuiFlexItem>
              ))}
            </EuiFlexGrid>
          ) : (
            <>
              {getCreatives().length && !creativesLoading ? (
                <EuiFlexGrid columns={3} wrap>
                  {getCreatives().map((val, index) => (
                    <EuiFlexItem style={{ flexGrow: 1 }} key={index}>
                      <Card
                        {...val}
                        creativeGroupId={creativeGroup.id}
                        selectedElements={selectedElements}
                        setSelectedElements={setSelectedElements}
                        refetch={() => {}}
                        isLoading={creativesLoading}
                        mode="client"
                        token={token}
                        onChangeData={(items) => onChangeData(items)}
                      />
                    </EuiFlexItem>
                  ))}
                </EuiFlexGrid>
              ) : (
                <EuiEmptyPrompt
                  iconType="editorStrike"
                  title={<h2>No assets found</h2>}
                  body={
                    <>
                      <p>There's no element matching the current filters</p>
                    </>
                  }
                />
              )}
            </>
          )}
        </div>
      </EuiFlexGroup>
    </EuiPage>
  );
}
