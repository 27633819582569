import React from "react";
import {
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageContent,
} from "@elastic/eui";
import { BrainLink } from "./brain-link";

const NotFound = () => {
  return (
    <EuiPageContent>
      <EuiFlexGroup
        responsive={false}
        justifyContent="spaceAround"
        alignItems="center"
        style={{ minHeight: "calc(100vh - 120px)" }}
      >
        <EuiFlexItem grow={false}>
          <EuiEmptyPrompt
            iconColor="danger"
            iconType="faceSad"
            titleSize="l"
            title={<h2>404</h2>}
            body={<p>La pagine che stai cercando non esiste!</p>}
            actions={[
              <BrainLink to="/">
                <EuiButtonEmpty color="subdued">Torna alla home</EuiButtonEmpty>
              </BrainLink>,
            ]}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPageContent>
  );
};

export default NotFound;
