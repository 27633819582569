import React from "react";
import useAxios from "axios-hooks";
import config from "../../config";
import _ from "lodash";
import { EuiComboBox, EuiFormRow } from "@elastic/eui";
import ErrorBlock from "../error-block";

const OrganizationSelect = ({
  disabled = false,
  isLoading,
  onOrganizationChange,
  selectedOrganization,
  enableAllOption = true,
}) => {
  const [
    {
      data: organizations,
      loading: organizationsLoading,
      error: organizationsError,
    },
  ] = useAxios({
    url: `${config.backend}/api/organizations`,
    method: "GET",
  });

  const getOrganizations = () => _.get(organizations, "organizations", []);

  const getOptions = () => {
    const allOption = {
      id: null,
      label: "All",
    };

    const options = _.map(getOrganizations(), (c) => {
      return {
        id: c.id,
        label: c.name,
      };
    });

    if (enableAllOption) {
      return [allOption, ...options];
    }

    return options;
  };

  if (organizationsError) {
    return <ErrorBlock />;
  }

  return (
    <EuiFormRow label="Organization" fullWidth>
      <EuiComboBox
        fullWidth
        isDisabled={disabled || organizationsLoading}
        isLoading={isLoading || organizationsLoading}
        placeholder="Select an organization"
        singleSelection={{ asPlainText: true }}
        onChange={(value) => {
          if (value.length < 1) {
            value = [
              {
                id: null,
                label: "All",
              },
            ];
          }
          onOrganizationChange(value[0].id);
        }}
        options={getOptions()}
        selectedOptions={
          _.find(
            getOptions(),
            (organization) => organization.id === selectedOrganization,
          )
            ? [
                _.find(
                  getOptions(),
                  (organization) => organization.id === selectedOrganization,
                ),
              ]
            : []
        }
      />
    </EuiFormRow>
  );
};

export default OrganizationSelect;
