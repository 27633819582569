import React from "react";
import {
  EuiPage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiEmptyPrompt,
} from "@elastic/eui";

const UnauthorizedPage = () => {
  return (
    <EuiPage>
      <EuiFlexGroup
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
        responsive={false}
      >
        <EuiFlexItem grow={false}>
          <EuiEmptyPrompt
            iconColor="danger"
            iconType="faceSad"
            titleSize="l"
            title={<h2>Non sei autorizzato a visitare questa pagina!</h2>}
            body={<p>Non hai i permessi per accedere ai dati</p>}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPage>
  );
};

export default UnauthorizedPage;
