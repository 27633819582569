import React, { useState } from "react";
import _ from "lodash";
import {
  EuiCollapsibleNav,
  EuiHorizontalRule,
  EuiFlexItem,
  EuiCollapsibleNavGroup,
  EuiShowFor,
  EuiListGroupItem,
} from "@elastic/eui";
import Header from "./header";
import { useLocation } from "react-router-dom";
import routes from "../../routes";
import useAuth from "../../hooks/auth";
import FacebookLogo from "../../assets/images/fb.png";
import TikTokLogo from "../../assets/images/tt.png";
import SideNavList from "./sidenav-list";
import Logo from "./logo";

const Layout = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth();

  const isAuthorizedLink = (route) => {
    if (user && user.roles) {
      let authRoles = _.map(user.roles, (r) => r.name);
      if (route.roles) {
        return _.some(authRoles, (r) => route.roles.hasOwnProperty(r));
      }
    }

    return true;
  };

  const [navIsOpen, setNavIsOpen] = useState(
    JSON.parse(localStorage.getItem("navIsOpen")) || false,
  );
  const [navIsDocked, setNavIsDocked] = useState(
    JSON.parse(localStorage.getItem("navIsDocked")) || false,
  );

  const [selectedSoftware] = useState({
    title: "Settings",
    logo: "managementApp",
    href: "/",
  });

  const [openGroups, setOpenGroups] = useState(
    JSON.parse(localStorage.getItem("openNavGroups")) || [],
  );

  const leadLinks = routes.filter((route) => {
    return route.menu === "lead" && isAuthorizedLink(route);
  });

  const campaignLinks = routes.filter((route) => {
    return route.menu === "campaign" && isAuthorizedLink(route);
  });

  const clientLinks = routes.filter((route) => {
    return route.menu === "client" && isAuthorizedLink(route);
  });

  const ABTestLinks = routes.filter((route) => {
    return route.menu === "abtest" && isAuthorizedLink(route);
  });

  const chatbotLinks = routes.filter((route) => {
    return route.menu === "chatbot" && isAuthorizedLink(route);
  });

  const offerLinks = routes.filter((route) => {
    return route.menu === "offer" && isAuthorizedLink(route);
  });

  const dollyLinks = routes.filter((route) => {
    return route.menu === "dolly" && isAuthorizedLink(route);
  });

  const creativeLinks = routes.filter((route) => {
    return route.menu === "creative" && isAuthorizedLink(route);
  });

  const facebookLinks = routes.filter((route) => {
    return route.menu === "facebook" && isAuthorizedLink(route);
  });

  const tiktokLinks = routes.filter((route) => {
    return route.menu === "tiktok" && isAuthorizedLink(route);
  });

  const adverstoryLinks = routes.filter((route) => {
    return route.menu === "adverstory" && isAuthorizedLink(route);
  });

  const potterLinks = routes.filter((route) => {
    return route.menu === "potter" && isAuthorizedLink(route);
  });

  const converbookLinks = routes.filter((route) => {
    return route.menu === "converbook" && isAuthorizedLink(route);
  });

  const brainLinks = routes.filter((route) => {
    return route.menu === "settings" && isAuthorizedLink(route);
  });

  const toolsLinks = routes.filter((route) => {
    return route.menu === "tools" && isAuthorizedLink(route);
  });

  const merlinLinks = routes.filter((route) => {
    return route.menu === "merlin" && isAuthorizedLink(route);
  });

  const flamelLinks = routes.filter((route) => {
    return route.menu === "flamel" && isAuthorizedLink(route);
  });

  const einsteinLinks = routes.filter((route) => {
    return route.menu === "einstein" && isAuthorizedLink(route);
  });

  const statsLinks = routes.filter((route) => {
    return route.menu === "stats" && isAuthorizedLink(route);
  });

  const wikiLinks = routes.filter((route) => {
    return route.menu === "wiki" && isAuthorizedLink(route);
  });

  const magnumBiLinks = routes.filter((route) => {
    return route.menu === "magnum-bi" && isAuthorizedLink(route);
  });

  const publicLinks = routes.filter((route) => {
    return !route.isPrivate;
  });

  const toggleAccordion = (isOpen, title) => {
    if (!title) {
      return;
    }

    const itExists = openGroups && openGroups.includes(title);

    if (isOpen) {
      if (itExists) {
        return;
      }

      setOpenGroups([...openGroups, title]);
    } else {
      const index = openGroups.indexOf(title);

      if (index > -1) {
        setOpenGroups([..._.clone(openGroups).splice(index, 1)]);
      }
    }

    localStorage.setItem("openNavGroups", JSON.stringify(openGroups));
  };

  if (
    !isAuthenticated ||
    _.find(publicLinks, (link) => link.path === location.pathname) ||
    _.split(location.pathname, "/")[1] === "public"
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <Header
        title={selectedSoftware.title}
        logo={selectedSoftware.logo}
        href={selectedSoftware.href}
        openMenu={() => {
          setNavIsOpen(!navIsOpen);
          localStorage.setItem("navIsOpen", !navIsOpen);

          setNavIsDocked(!navIsDocked);
          localStorage.setItem("navIsDocked", !navIsDocked);
        }}
      />

      <EuiCollapsibleNav
        id="guideCollapsibleNavAllExampleNav"
        aria-label="Main navigation"
        isOpen={navIsOpen}
        isDocked={navIsDocked}
        onClose={() => {
          setNavIsOpen(false);
          localStorage.setItem("navIsOpen", false);
        }}
      >
        <EuiFlexItem
          grow={false}
          style={{
            flexShrink: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo image={user?.organization?.settings?.logo} />
        </EuiFlexItem>

        <EuiHorizontalRule margin="none" />

        <EuiFlexItem className="eui-yScroll">
          {clientLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Clienti"
              iconType="logoCloud"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Client")}
            >
              <SideNavList links={clientLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {campaignLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Campagne"
              iconType="logoElasticsearch"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Campaign")}
            >
              <SideNavList links={campaignLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {leadLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Lead"
              iconType="logoElasticStack"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Lead")}
            >
              <SideNavList links={leadLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {statsLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Statistiche"
              iconType="logoObservability"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Statistiche")}
            >
              <SideNavList links={statsLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {creativeLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Creatività"
              iconType="logoAppSearch"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Creatività")}
            >
              <SideNavList links={creativeLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {offerLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Offerte"
              iconType="logoSiteSearch"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Offerte")}
            >
              <SideNavList links={offerLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {dollyLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Dolly"
              iconType="logoBeats"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Dolly")}
            >
              <SideNavList links={dollyLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {chatbotLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Vinta"
              iconType="logoCloudEnterprise"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Vinta")}
            >
              <SideNavList links={chatbotLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {adverstoryLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Adverstory"
              iconType="logoCode"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Adverstory")}
            >
              <SideNavList links={adverstoryLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {potterLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Potter"
              iconType="logoMetrics"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Potter")}
            >
              <SideNavList links={potterLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {converbookLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Converbook"
              iconType="logoLogstash"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Converbook")}
            >
              <SideNavList links={converbookLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {merlinLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Merlin"
              iconType="logoEnterpriseSearch"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Merlin")}
            >
              <SideNavList links={merlinLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {flamelLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Flamel"
              iconType="logoLogging"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Flamel")}
            >
              <SideNavList links={flamelLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {ABTestLinks.length ? (
            <EuiCollapsibleNavGroup
              title="A/B Test"
              iconType="logoWorkplaceSearch"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "A/B Test")}
            >
              <SideNavList links={ABTestLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {einsteinLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Einstein"
              iconType="logoMaps"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Einstein")}
            >
              <SideNavList links={einsteinLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {facebookLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Facebook"
              iconType={FacebookLogo}
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Facebook")}
            >
              <SideNavList links={facebookLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {tiktokLinks.length ? (
            <EuiCollapsibleNavGroup
              title="TikTok"
              iconType={TikTokLogo}
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "TikTok")}
            >
              <SideNavList links={tiktokLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {magnumBiLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Magnum BI"
              iconType="logoUptime"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Magnum BI")}
            >
              <SideNavList links={magnumBiLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {toolsLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Tools"
              iconType="logoBusinessAnalytics"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Tools")}
            >
              <SideNavList links={toolsLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          <EuiCollapsibleNavGroup />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiCollapsibleNavGroup />
          {wikiLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Wiki"
              iconType="logoUptime"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Wiki")}
            >
              <SideNavList links={wikiLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          {/* Settings section */}
          {brainLinks.length ? (
            <EuiCollapsibleNavGroup
              title="Settings"
              iconType="managementApp"
              isCollapsible={true}
              initialIsOpen={false}
              onToggle={(isOpen) => toggleAccordion(isOpen, "Settings")}
            >
              <SideNavList links={brainLinks} />
            </EuiCollapsibleNavGroup>
          ) : null}

          <EuiShowFor sizes={["l", "xl"]}>
            <EuiCollapsibleNavGroup>
              <EuiListGroupItem
                size="xs"
                color="subdued"
                label={`${navIsDocked ? "Undock" : "Dock"} navigation`}
                onClick={() => {
                  setNavIsDocked(!navIsDocked);
                  localStorage.setItem(
                    "navIsDocked",
                    JSON.stringify(!navIsDocked),
                  );
                }}
                iconType={navIsDocked ? "lock" : "lockOpen"}
              />
            </EuiCollapsibleNavGroup>
          </EuiShowFor>
        </EuiFlexItem>
      </EuiCollapsibleNav>

      {children}
    </>
  );
};

export default Layout;
