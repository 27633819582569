import {
  EuiAccordion,
  EuiBadge,
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLoadingContent,
  EuiPage,
  euiPaletteColorBlindBehindText,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import config from "../../../config";
import Card from "../creative-group/components/card";
import LoadingCard from "../creative-group/components/loading-card";
import axios from "axios";
import useAuth from "../../../hooks/auth";
import PublicApproveButton from "../components/approve-button";
import { BrainLink } from "../../../components/brain-link";
import spinupLogo from "../../../assets/images/logo.svg";

export default function LeadCampaignPage() {
  const { user } = useAuth();
  const [selectedElements, setSelectedElements] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  const [leadCampaign, setLeadCampaign] = useState();
  const [leadCampaignLoading, setLeadCampaignLoading] = useState(false);

  const init = async () => {
    if (leadCampaignLoading) {
      return;
    }

    try {
      setLeadCampaignLoading(true);

      const response = await axios.get(
        `${config.backend}/api/public/lead-campaigns/${id}`,
      );

      setLeadCampaign(response.data.lead_campaign);

      setLeadCampaignLoading(false);
    } catch (error) {
      setLeadCampaignLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      history.push("/unauthorized");
    } else {
      init();
    }
  }, []);

  return (
    <EuiPage>
      <EuiFlexGroup direction="column" alignItems="center">
        <EuiSpacer />

        <EuiFlexItem grow={false} style={{ width: 320, alignItems: "center" }}>
          <EuiImage size="m" url={spinupLogo} alt="spinup" />
        </EuiFlexItem>

        <EuiSpacer size="s" />

        <EuiFlexItem grow={false} style={{ alignItems: "center" }}>
          <EuiTitle size="m">
            {leadCampaignLoading ? (
              <div style={{ width: 100 }}>
                <EuiLoadingContent lines={1} />
              </div>
            ) : (
              <h1>{(leadCampaign && leadCampaign.name) || ""}</h1>
            )}
          </EuiTitle>
        </EuiFlexItem>

        <EuiSpacer size="s" />

        <div style={{ padding: 16, width: "100%", maxWidth: 1280 }}>
          {leadCampaignLoading ? (
            <EuiFlexGrid columns={3} wrap>
              {_.map(_.times(6, String), (i, index) => (
                <EuiFlexItem key={index}>
                  <LoadingCard />
                </EuiFlexItem>
              ))}
            </EuiFlexGrid>
          ) : (
            <>
              {leadCampaign?.creative_groups?.length ? (
                <>
                  {_.map(leadCampaign.creative_groups, (creative_group) => (
                    <>
                      <EuiPanel key={creative_group.id}>
                        <EuiAccordion
                          initialIsOpen
                          extraAction={
                            <EuiBadge
                              style={{ marginLeft: 6 }}
                              color={
                                euiPaletteColorBlindBehindText()[
                                  Math.floor(
                                    creative_group.client.name.length %
                                      euiPaletteColorBlindBehindText().length,
                                  )
                                ]
                              }
                            >
                              {creative_group.client.name}
                            </EuiBadge>
                          }
                          id={creative_group.id}
                          buttonContent={creative_group.name}
                          paddingSize="l"
                        >
                          <EuiFlexItem
                            grow={false}
                            style={{ alignItems: "center" }}
                          >
                            <EuiTitle size="s">
                              <h1>
                                {(creative_group && creative_group.name) || ""}
                              </h1>
                            </EuiTitle>

                            <EuiSpacer />

                            {creative_group ? (
                              <EuiFlexGroup>
                                {user ? (
                                  <EuiFlexItem>
                                    <BrainLink
                                      to={`/creative-groups/${creative_group?.id}/edit`}
                                    >
                                      <EuiButton iconType="pencil">
                                        Edit
                                      </EuiButton>
                                    </BrainLink>
                                  </EuiFlexItem>
                                ) : null}

                                <EuiFlexItem>
                                  <PublicApproveButton
                                    creativeGroupId={creative_group?.id}
                                    creativeGroupStatus={creative_group?.status}
                                  />
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            ) : null}

                            <EuiSpacer />

                            <>
                              {creative_group.creatives.length ? (
                                <EuiFlexGrid
                                  columns={3}
                                  wrap
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  {creative_group.creatives.map(
                                    (val, index) => (
                                      <EuiFlexItem
                                        style={{
                                          flexGrow: 1,
                                          alignItems: "center",
                                        }}
                                        key={index}
                                      >
                                        <Card
                                          {...val}
                                          creativeGroupId={creative_group.id}
                                          isLoading={leadCampaignLoading}
                                          mode="client"
                                          selectedElements={selectedElements}
                                          setSelectedElements={
                                            setSelectedElements
                                          }
                                        />
                                      </EuiFlexItem>
                                    ),
                                  )}
                                </EuiFlexGrid>
                              ) : !leadCampaignLoading &&
                                !creative_group.creatives.length ? (
                                <EuiEmptyPrompt
                                  iconType="editorStrike"
                                  title={<h2>No assets found</h2>}
                                  body={
                                    <>
                                      <p>
                                        There's no element matching the current
                                        filters
                                      </p>
                                    </>
                                  }
                                />
                              ) : null}
                            </>
                          </EuiFlexItem>
                        </EuiAccordion>
                      </EuiPanel>
                      <EuiSpacer />
                    </>
                  ))}
                </>
              ) : !leadCampaignLoading &&
                leadCampaign &&
                !leadCampaign.creative_groups?.length ? (
                <EuiEmptyPrompt
                  iconType="editorStrike"
                  title={<h2>No assets found</h2>}
                  body={
                    <>
                      <p>There's no element matching the current filters</p>
                    </>
                  }
                />
              ) : null}
            </>
          )}
        </div>
      </EuiFlexGroup>
    </EuiPage>
  );
}
