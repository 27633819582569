import React, { useState } from "react";
import {
  EuiFlexGroup,
  EuiForm,
  EuiPanel,
  EuiSpacer,
  EuiFormRow,
  EuiTitle,
  EuiPage,
  EuiButton,
  EuiCallOut,
  EuiFieldPassword,
  EuiFlexItem,
} from "@elastic/eui";
import _ from "lodash";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/auth";
import useToast from "../../hooks/toast";
import { strengthPasswordRegEx } from "../../constants";

const UpdatePasswordPage = () => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { register, errors, watch, handleSubmit } = useForm();
  const [backendErrors, setBackendErrors] = useState();
  const { passwordUpdate } = useAuth();

  const onSubmit = (data) => {
    setIsLoading(true);
    setBackendErrors();
    passwordUpdate({
      credentials: data,
      onSuccess: () => {
        setIsLoading(false);
        window.location.href = "/";
      },
      onError: (e) => {
        setIsLoading(false);
        setBackendErrors(_.map(e.response.data.errors, (error) => error));
      },
    });
  };

  return (
    <EuiPage>
      <EuiFlexGroup
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
        responsive={false}
      >
        <EuiFlexItem grow={false}>
          <EuiCallOut
            style={{ width: 640 }}
            color="warning"
            size="l"
            title="Per motivi di sicurezza, dopo il primo accesso e dopo un certo periodo di tempo, devi modificare la tua password personale"
            iconType="help"
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiTitle>
            <h1>Modifica Password</h1>
          </EuiTitle>
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiPanel style={{ width: 380 }}>
            <EuiForm
              component="form"
              isInvalid={backendErrors}
              error={backendErrors}
              onSubmit={handleSubmit(onSubmit)}
            >
              <EuiSpacer />
              <EuiFormRow
                label="Vecchia password"
                fullWidth
                error={errors.old_password && ["Questo campo è richiesto"]}
                isInvalid={errors.old_password}
              >
                <EuiFieldPassword
                  fullWidth
                  type="dual"
                  name="old_password"
                  isInvalid={errors.old_password}
                  inputRef={register({
                    required: true,
                  })}
                />
              </EuiFormRow>

              <EuiSpacer />

              <EuiFormRow
                label="Nuova password"
                fullWidth
                isInvalid={errors.new_password}
                error={errors.old_password && ["Questo campo è richiesto"]}
              >
                <EuiFieldPassword
                  type="dual"
                  fullWidth
                  isInvalid={errors.new_password}
                  name="new_password"
                  inputRef={register({
                    required: true,
                    minLength: {
                      value: 8,
                      message: "La password deve contenere almeno 8 caratteri",
                    },
                    pattern: {
                      value: strengthPasswordRegEx,
                      message:
                        "La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola,  un numero e un carattere speciale",
                    },
                  })}
                />
              </EuiFormRow>

              <EuiSpacer />

              <EuiFormRow
                label="Conferma nuova password"
                isInvalid={
                  errors.new_password_confirmation && [
                    "Questo campo è richiesto",
                  ]
                }
                error={
                  (errors.new_password_confirmation && [
                    errors.new_password_confirmation.message,
                  ]) || ["La conferma password deve combaciare"]
                }
                fullWidth
              >
                <EuiFieldPassword
                  type="dual"
                  fullWidth
                  name="new_password_confirmation"
                  inputRef={register({
                    required: "Questo campo è richiesto",
                    minLength: {
                      value: 8,
                      message: "La password deve contenere almeno 8 caratteri",
                    },
                    pattern: {
                      value: strengthPasswordRegEx,
                      message:
                        "La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola,  un numero e un carattere speciale",
                    },
                    validate: (value) => value === watch("new_password"),
                  })}
                />
              </EuiFormRow>

              <EuiSpacer />

              <EuiButton fullWidth type="submit" fill isLoading={isLoading}>
                Conferma
              </EuiButton>
            </EuiForm>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPage>
  );
};

export default UpdatePasswordPage;
