import {
  EuiLoadingContent,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";

import React from "react";

const LoadingCard = () => {
  return (
    <EuiFlexGroup
      gutterSize="none"
      responsive={false}
      alignItems="center"
      justifyContent="spaceAround"
      direction="column"
      style={{
        width: "100%",
      }}
    >
      <EuiFlexItem style={{ width: "100%" }} grow>
        <EuiPanel
          paddingSize="none"
          style={{
            width: "100%",
            minWidth: 200,
            minHeight: 200,
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <div style={{ padding: 8 }}>
            <EuiFlexGroup
              gutterSize="none"
              justifyContent="spaceBetween"
              style={{ width: "100%" }}
            >
              <EuiFlexItem grow={false}>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <div style={{ width: 100 }}>
                      <EuiLoadingContent lines={1} />
                    </div>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <div style={{ width: 100 }}>
                  <EuiLoadingContent lines={1} />
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          </div>

          <div style={{ position: "relative", cursor: "pointer" }}>
            <div
              style={{
                width: "100%",
                paddingBottom: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "#000",
              }}
            />
          </div>

          <EuiSpacer />

          <div style={{ marginLeft: 16, marginRight: 16 }}>
            <EuiFlexGroup
              gutterSize="s"
              alignItems="center"
              justifyContent="center"
            >
              <EuiLoadingContent lines={2} />
            </EuiFlexGroup>
          </div>

          <EuiSpacer size="m" />

          <div style={{ marginLeft: 16, marginRight: 16 }}>
            <EuiLoadingContent lines={4} />
          </div>

          <EuiSpacer size="l" />
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default LoadingCard;
