import React from "react";
import {
  EuiConfirmModal,
  EuiLoadingSpinner,
  EuiOverlayMask,
} from "@elastic/eui";

const ConfirmModal = ({
  title,
  children,
  onCancel,
  onConfirm,
  type,
  show,
  isLoading,
}) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <EuiOverlayMask>
        <EuiConfirmModal
          title={title}
          onCancel={onCancel}
          onConfirm={onConfirm}
          cancelButtonText="Cancel"
          confirmButtonDisabled={isLoading}
          confirmButtonText={
            isLoading ? (
              <>
                {" "}
                <EuiLoadingSpinner size="s" /> &nbsp;&nbsp;
                <span>Loading</span>
              </>
            ) : (
              "Confirm"
            )
          }
          buttonColor={type}
          defaultFocusedButton="confirm"
        >
          {children}
        </EuiConfirmModal>
      </EuiOverlayMask>
    </>
  );
};

export default ConfirmModal;
