import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

const SideNavList = ({ links }) => {
  return (
    <nav className="euiSideNav euiListGroup">
      <div>
        {_.map(links, (link) => (
          <li
            key={link.path}
            className="euiListGroupItem 
        euiListGroupItem--small
        euiListGroupItem--subdued
        euiListGroupItem-isClickable"
          >
            <Link className="euiListGroupItem__button" to={link.path}>
              {link.label}
            </Link>
          </li>
        ))}
      </div>
    </nav>
  );
};

export default SideNavList;
