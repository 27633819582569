import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from "@elastic/eui";

const Loader = () => {
  return (
    <EuiFlexGroup
      responsive={false}
      justifyContent="spaceAround"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <EuiFlexItem grow={false}>
        <EuiLoadingSpinner size="xl" />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Loader;
