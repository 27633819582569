import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect,
} from "react";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import Loader from "../../components/loader";
import config from "../../config";
import useToast from "../../hooks/toast";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const hasJWT = () => {
    return (
      !!localStorage.getItem("jwt") &&
      !!localStorage.getItem("jwt_expiration_date")
    );
  };

  const { addToast } = useToast();

  const initialState = {
    isLoading: true,
    isAuthenticated: hasJWT(),
    user: null,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "IS_LOADING_UPDATE":
        return {
          ...state,
          isLoading: action.payload,
        };

      case "IS_AUTHENTICATED_UPDATE":
        return {
          ...state,
          isAuthenticated: action.payload,
        };

      case "USER_DATA_UPDATE":
        return {
          ...state,
          user: action.payload,
        };

      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await axios.get(`/api/auth/me`);

      dispatch({
        type: "USER_DATA_UPDATE",
        payload: response.data,
      });

      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: false,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.post(`/api/auth/refresh`);

      localStorage.setItem("jwt", response.data.access_token);
      localStorage.setItem(
        "jwt_expiration_date",
        moment()
          .add(response.data.expires_in || 3600, "seconds")
          .format(),
      );

      dispatch({
        type: "IS_AUTHENTICATED_UPDATE",
        payload: true,
      });

      return;
    } catch (e) {
      console.error("Could not refresh token");
      return Promise.reject(e);
    }
  };

  const userUpdate = async ({ userId, data, onSuccess = () => {} }) => {
    try {
      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: true,
      });

      const response = await axios(`${config.backend}/api/users/${userId}`, {
        method: "POST",
        data,
      });

      if (!response.data.success) {
        addToast({
          title: `Error`,
          iconType: "alert",
          color: "danger",
          text: <p>{response.data.message}</p>,
        });

        dispatch({
          type: "IS_LOADING_UPDATE",
          payload: false,
        });
      } else {
        dispatch({
          type: "USER_DATA_UPDATE",
          payload: response.data.user,
        });

        addToast({
          title: "User data changed!",
          iconType: "check",
          color: "success",
        });

        onSuccess();
      }
    } catch (e) {
      addToast({
        title: "Error",
        iconType: "alert",
        color: "danger",
        text: <p>{e.response.data.message}</p>,
      });

      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: false,
      });
    }
  };

  const logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("jwt_expiration_date");

    dispatch({
      type: "IS_AUTHENTICATED_UPDATE",
      payload: false,
    });

    dispatch({
      type: "USER_DATA_UPDATE",
      payload: null,
    });
  };

  const login = async ({ credentials, onSuccess = () => {} }) => {
    try {
      localStorage.removeItem("jwt");
      localStorage.removeItem("jwt_expiration_date");

      const response = await axios(`/api/auth/login`, {
        method: "post",
        data: credentials,
      });

      localStorage.setItem("jwt", response.data.access_token);
      localStorage.setItem(
        "jwt_expiration_date",
        moment()
          .add(response.data.expires_in || 3600, "seconds")
          .format(),
      );

      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: true,
      });

      dispatch({
        type: "IS_AUTHENTICATED_UPDATE",
        payload: true,
      });

      onSuccess();

      return response;
    } catch (e) {
      throw e;
    }
  };

  const get2FASecret = async () => {
    try {
      const response = await axios(`/api/auth/2fa/secret`, {
        method: "get",
      });

      return response;
    } catch (e) {
      throw e;
    }
  };

  const activate2FA = async ({ otp }) => {
    try {
      const response = await axios(`/api/auth/2fa/activate`, {
        method: "post",
        data: {
          one_time_password: otp,
        },
      });

      return response;
    } catch (e) {
      throw e;
    }
  };

  const passwordUpdate = async ({
    credentials,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    try {
      const response = await axios(`/api/auth/password/update`, {
        method: "post",
        data: credentials,
      });

      dispatch({
        type: "USER_DATA_UPDATE",
        payload: response.data.user,
      });

      onSuccess();
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    if (!state.isAuthenticated) {
      dispatch({
        type: "IS_LOADING_UPDATE",
        payload: false,
      });

      return;
    }

    fetchUserInfo();
  }, [fetchUserInfo, state.isAuthenticated]);

  axios.defaults.baseURL = config.backend;

  axios.interceptors.request.use((config) => {
    const jwt = localStorage.getItem("jwt");

    config.headers.common["Content-Type"] = "application/json";
    config.headers.common["Accept"] = "application/json";

    if (jwt) {
      config.headers.common["Authorization"] = `Bearer ${jwt}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (_.get(error, "response.data.message") === "PASSWORD_OUTDATED") {
        window.location.href = "/password/update";
        return Promise.reject(error);
      }

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          await refreshToken();

          const jwt = localStorage.getItem("jwt");

          if (jwt) {
            originalRequest.headers["Authorization"] = `Bearer ${jwt}`;
            return axios(originalRequest);
          } else {
            throw new Error("No token available");
          }
        } catch (e) {
          localStorage.removeItem("jwt");
          localStorage.removeItem("jwt_expiration_date");

          console.error("Could not refresh token", e);

          window.location.href = "/login";
          return Promise.reject(e);
        }
      }

      return Promise.reject(error);
    },
  );

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        fetchUserInfo,
        login,
        passwordUpdate,
        userUpdate,
        get2FASecret,
        activate2FA,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
