import React, { useState } from "react";
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiPopover,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import useAuth from "../../../../hooks/auth";
import useModal from "../../../../hooks/modal";
import ConfirmModal from "../../../confirm-modal";
import { checkRole } from "../../../../utils";
import OrganizationSelect from "../../../organization-select";
import { BrainLink } from "../../../brain-link";

export default function HeaderProfile() {
  const { user, isLoading, logout, userUpdate } = useAuth();
  const isSuperAdmin = checkRole(user, "super-admin");
  const [isModalOpen, setIsModalOpen] = useModal();

  const [isOpen, setIsOpen] = useState(false);

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const button = (
    <EuiHeaderSectionItemButton
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-label="Account menu"
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={user.name} size="s" />
    </EuiHeaderSectionItemButton>
  );

  return (
    <EuiPopover
      ownFocus
      button={button}
      isOpen={isOpen}
      anchorPosition="downRight"
      closePopover={closeMenu}
      panelPaddingSize="none"
    >
      <ConfirmModal
        isLoading={isLoading}
        title="Logout"
        show={isModalOpen}
        type="danger"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => logout()}
      >
        <p>Sei sicuro di voler uscire?</p>
      </ConfirmModal>

      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize="m"
          className="euiHeaderProfile"
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <EuiAvatar name={user.name} size="xl" />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{user.name}</p>
            </EuiText>

            {isSuperAdmin && (
              <>
                <EuiSpacer size="s" />
                <OrganizationSelect
                  enableAllOption={false}
                  selectedOrganization={user.organization_id}
                  onOrganizationChange={(organization) => {
                    userUpdate({
                      userId: user.id,
                      data: { ...user, organization_id: organization },
                      onSuccess: () => {
                        window.location.href = "/";
                      },
                    });
                  }}
                />
              </>
            )}

            <EuiSpacer size="m" />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent="spaceBetween">
                  <EuiFlexItem grow={false}>
                    <BrainLink to="/profile">
                      <EuiLink>Profilo</EuiLink>
                    </BrainLink>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiLink
                      color="danger"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      Log out
                    </EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  );
}
