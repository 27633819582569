import React, { useState } from "react";
import {
  EuiPage,
  EuiForm,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiFormRow,
  EuiButton,
  EuiFieldPassword,
  EuiFieldNumber,
  EuiFieldText,
  EuiCallOut,
  EuiText,
  EuiTextAlign,
  EuiLink,
} from "@elastic/eui";
import { validationEmailRegEx } from "../../constants";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/auth";
import { useHistory } from "react-router-dom";
import PrivacyPolicy from "../../assets/documents/privacy_policy_brain.pdf";

const LoginPage = () => {
  const history = useHistory();
  const { login } = useAuth();
  const { handleSubmit, register, errors, setError, clearErrors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);

  const onSubmit = async (credentials) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await login({
        credentials,
        onSuccess: () => {
          setIsLoading(false);

          history.push("/");
        },
      });
    } catch (e) {
      setIsLoading(false);

      if (e.response.status === 422) {
        setError("server", {
          type: "noMatch",
          message:
            "Non abbiamo trovato nessun utente che corrisponde a queste credenziali",
        });
      } else if (
        e.response.status === 400 &&
        e.response.data.message === "OTP_WRONG_OR_MISSING"
      ) {
        if (showOtpInput) {
          setError("otp", {
            type: "invalid",
            message: "L'OTP inserito non è valido",
          });
        }

        setShowOtpInput(true);
      } else {
        setError("server", {
          type: "error",
          message: "Errore di server. Riprova più tardi",
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <EuiPage>
      <EuiFlexGroup
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
        responsive={false}
      >
        <EuiSpacer />

        {errors && errors.server ? (
          <EuiCallOut
            style={{ width: 320 }}
            title={errors.server.message}
            color="danger"
            iconType="alert"
          ></EuiCallOut>
        ) : null}

        <EuiSpacer />

        <EuiFlexItem grow={false}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <EuiForm>
              <EuiPanel style={{ width: 320 }}>
                <>
                  <EuiFormRow
                    label="Email"
                    type="email"
                    isInvalid={errors && errors.email}
                    error={errors && errors.email && [errors.email.message]}
                  >
                    <EuiFieldText
                      inputRef={register({
                        required: "L'email è richiesta",
                        pattern: {
                          value: validationEmailRegEx,
                          message: "Questa email non è valida",
                        },
                      })}
                      name="email"
                      isInvalid={errors && errors.email}
                      icon="user"
                    />
                  </EuiFormRow>

                  <EuiFormRow
                    label="Password"
                    isInvalid={errors && errors.password}
                    error={
                      errors && errors.password && [errors.password.message]
                    }
                  >
                    <EuiFieldPassword
                      inputRef={register({
                        required: "La password è richiesta",
                      })}
                      name="password"
                      isInvalid={errors && errors.password}
                    />
                  </EuiFormRow>

                  {showOtpInput && (
                    <EuiFormRow
                      label="OTP"
                      type="OTP"
                      isInvalid={errors && errors.otp}
                      error={errors && errors.otp && [errors.otp.message]}
                    >
                      <EuiFieldNumber
                        inputRef={register({
                          required: "L'OTP è richiesto",
                        })}
                        name="one_time_password"
                      />
                    </EuiFormRow>
                  )}

                  <EuiSpacer size="l" />

                  <EuiButton
                    isLoading={isLoading}
                    onClick={() => clearErrors()}
                    fullWidth
                    type="submit"
                  >
                    {isLoading ? null : "Login"}
                  </EuiButton>
                </>
              </EuiPanel>
            </EuiForm>
          </form>

          <EuiSpacer />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EuiTextAlign textAlign="center">
            <EuiText size="xs" color="subdued">
              Prima di utilizzare questa piattaforma leggi{" "}
              <EuiLink href={PrivacyPolicy} target="_blank">
                l'Informativa Privacy
              </EuiLink>
            </EuiText>
          </EuiTextAlign>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPage>
  );
};

export default LoginPage;
