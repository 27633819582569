import fbLogo from "../assets/images/fb.png";
import ttLogo from "../assets/images/tt.png";

// TODO: Move all brain-sections to routes, no need to keep them detached
export const brainSections = [
  {
    id: "flamel",
    path: "flamel",
    name: "Flamel",
    icon: "logoLogging",
    href: "/flamel/plannings",
  },
  {
    id: "lead",
    name: "Lead",
    path: "leads",
    icon: "logoElasticStack",
    href: "/leads",
  },
  {
    id: "campaign",
    name: "Campagne",
    path: "campaigns",
    icon: "logoElasticsearch",
    href: "/campaigns",
  },
  {
    id: "client",
    path: "clients",
    name: "Clienti",
    icon: "logoCloud",
    href: "/clients",
  },
  {
    id: "chatbot",
    name: "Chatbot",
    path: "chatbots",
    icon: "logoCloudEnterprise",
    href: "/chatbots",
  },
  {
    id: "adverstory",
    path: "adverstories",
    name: "Adverstory",
    icon: "logoCode",
    href: "/adverstories",
  },
  {
    id: "abtest",
    name: "A/B Test",
    path: "abtests",
    icon: "logoWorkplaceSearch",
    href: "/abtests",
  },
  {
    id: "facebook",
    name: "Facebook",
    path: "facebook",
    icon: fbLogo,
    href: "/facebook/accounts",
  },
  {
    id: "tiktok",
    name: "TikTok",
    path: "tiktok",
    icon: ttLogo,
    href: "/tiktok/instant-forms",
  },
  {
    id: "tools",
    name: "Tools",
    path: "tools",
    icon: "logoBusinessAnalytics",
    href: "/tools/export",
  },
  {
    id: "stats",
    name: "Statistiche",
    path: "stats",
    icon: "logoObservability",
    href: "/stats/general",
  },
  {
    id: "wiki",
    name: "Wiki",
    path: "wiki",
    icon: "logoUptime",
    href: "/wiki",
  },
  {
    id: "potter",
    path: "wizards",
    name: "Potter",
    icon: "logoMetrics",
    href: "/wizards",
  },
  {
    id: "merlin",
    path: "merlin",
    name: "Merlin",
    icon: "logoEnterpriseSearch",
    href: "/merlin",
  },
  {
    id: "magnum-bi",
    path: "magnum-bi",
    name: "Magnum BI",
    icon: "logoUptime",
    href: "/magnum-bi",
  },
  {
    id: "einstein",
    path: "einstein",
    name: "Einstein",
    icon: "logoMaps",
    href: "/einstein",
  },
];
