import React, { useEffect, useState } from "react";
import { EuiButton } from "@elastic/eui";
import useToast from "../../../../hooks/toast";
import config from "../../../../config";
import axios from "axios";

const PublicApproveButton = ({ creativeGroupId, creativeGroupStatus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    if (creativeGroupStatus === 3) {
      setApproved(true);
    }
  }, []);

  const approveCreativeGroup = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading("true");
      const response = await axios.post(
        `${config.backend}/api/public/creative-groups/${creativeGroupId}`,
        {
          send_slack_notification: true,
          status: 3,
        },
      );
      setIsLoading(false);
      setApproved(true);
      addToast({
        title: `Creative group approved!`,
        iconType: "check",
        color: "success",
      });
    } catch (error) {
      addToast({
        title: `Error!: ${error.response.data.message}`,
        iconType: "cross",
        color: "danger",
      });
      setIsLoading(false);
    }
  };

  return (
    <EuiButton
      color="secondary"
      fill={approved}
      disabled={approved}
      isLoading={isLoading}
      onClick={() => approveCreativeGroup()}
      iconType="heart"
    >
      {`Approve${approved ? "d" : ""}`}
    </EuiButton>
  );
};

export default PublicApproveButton;
