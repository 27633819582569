import React from "react";
import {
  EuiPageBody,
  EuiPage,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiPageContent,
  EuiPageContentBody,
} from "@elastic/eui";

const PageWrapper = ({
  children,
  title,
  subtitle,
  actions,
  pageContentStyle = { overflowX: "scroll" },
}) => {
  return (
    <EuiPage>
      <EuiPageBody className="euiNavDrawerPage__pageBody">
        <EuiPageHeader>
          <EuiPageHeaderSection>
            <EuiTitle size="m">
              <h2>{title}</h2>
            </EuiTitle>
          </EuiPageHeaderSection>
          <EuiPageHeaderSection>{actions}</EuiPageHeaderSection>
        </EuiPageHeader>

        {subtitle}

        <EuiPageContent style={pageContentStyle}>
          <EuiPageContentBody>{children}</EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default PageWrapper;
