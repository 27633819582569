import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import routes from "./routes";
import useAuth from "./hooks/auth";
import Layout from "./components/_layout";
import moment from "moment";
import UnauthorizedPage from "./pages/unauthorized";
import useMetaTitle from "./hooks/meta-title";
import Loader from "./components/loader";
import NotFound from "./components/NotFound";

function RouteHandler({ title, isPrivate, roles, component, exact, path }) {
  useMetaTitle(`${title} - Brain`);

  const { isAuthenticated, user } = useAuth();

  if (isPrivate) {
    if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }
    if (
      !user.password_updated_at ||
      moment().diff(moment(user.password_updated_at), "months") > 5
    ) {
      return <Redirect to="/password/update" />;
    }
  }

  if (roles) {
    let authRoles = (user.roles ?? []).map((r) => r.name);
    let isAuthorized = authRoles.some((r) => roles.hasOwnProperty(r));

    if (!isAuthorized) {
      return <UnauthorizedPage />;
    }
  }

  return <Route path={path} exact={exact} component={component} />;
}

export default function RouterComponent() {
  return (
    <Router>
      <Layout>
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes.map((route, index) => (
              <RouteHandler
                title={route.title}
                isPrivate={route.isPrivate}
                roles={route.roles}
                // TODO: Possible that key can be the same as path, because are unique
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            <Route path={"*"} component={NotFound} />
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  );
}
