import React from "react";
import Router from "./router";
import { ToastProvider } from "./context/toast";
import { AuthProvider } from "./context/auth";
import "../src/sass/app.scss";

function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;
