import _ from "lodash";

export const removeQueryParamsFromURL = (url) => {
  if (/(http(s?).+)(\?.+)/.exec(url)) {
    return /(http(s?).+)(\?.+)/.exec(url)[1];
  }
  return url;
};

export const handleSelectionChange = (
  { item, selectedList, join },
  value = "query",
) => {
  let newList = [];

  if (_.find(selectedList, (i) => i === item[value])) {
    newList = _.filter(selectedList, (s) => s !== item[value]);
  } else {
    newList = selectedList.concat(item[value]);
  }

  if (join) {
    return _.join(newList, ",");
  }
  return newList;
};

export const checkRole = (user, role) => {
  let authRoles = _.map(user.roles, (r) => r.name);

  return _.includes(authRoles, role);
};
