import { useContext } from "react";
import { ToastContext } from "../../context/toast";

/**
 * @typedef {import('@elastic/eui').Toast} Toast
 * @typedef {(toast: Toast) => void} OnToast
 */

/**
 * TODO: Move it. Hooks should stay near their providers in the file system
 * @returns {{addToast: OnToast, removeToast: OnToast}}
 */
export default function useToast() {
  const context = useContext(ToastContext);
  if (context !== undefined) return context;
  throw new Error("useToast should be used inside ToastProvider");
}
