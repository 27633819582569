import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  EuiAccordion,
  EuiAvatar,
  EuiBadge,
  EuiButton,
  EuiCommentList,
  EuiTextArea,
  EuiIcon,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import useToast from "../../../../../hooks/toast";
import { linkTypes } from "../../../../../constants";

const Card = ({
  id,
  type = "image",
  status,
  payload = "https://i.imgur.com/RAGIhIQ.png",
  activity,
  additional_settings,
  link,
  comments,
  creativeGroupId,
  selectedElements,
  setSelectedElements,
  onChangeData,
  token,
}) => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing] = useState(false);
  const [draftPayload, setDraftPayload] = useState(payload);

  const events = [
    "marked the asset as draft",
    "submitted for review",
    "requested a review",
    "approved the asset",
    "rejected the asset",
    "archived the asset",
  ];

  const icons = [
    null,
    <EuiIcon type="refresh" color="primary" />,
    <EuiIcon type="glasses" color="warning" />,
    <EuiIcon type="checkInCircleFilled" color="secondary" />,
    <EuiIcon type="crossInACircleFilled" color="danger" />,
  ];

  const typeBadges = [
    <EuiBadge color="hollow">Image</EuiBadge>,
    <EuiBadge color="hollow">Video</EuiBadge>,
    <EuiBadge color="hollow">Body</EuiBadge>,
    <EuiBadge color="hollow">Title</EuiBadge>,
    <EuiBadge color="hollow">Description</EuiBadge>,
    <EuiBadge color="hollow">Internal asset</EuiBadge>,
    <EuiBadge color="hollow">External asset</EuiBadge>,
  ];

  const statusBadges = [
    <EuiBadge color="default">Draft</EuiBadge>,
    <EuiBadge color="primary">Pending review</EuiBadge>,
    <EuiBadge color="warning">Update requested</EuiBadge>,
    <EuiBadge color="secondary">Approved</EuiBadge>,
    <EuiBadge color="danger">Rejected</EuiBadge>,
    <EuiBadge color="#a5a5a5">Archived</EuiBadge>,
  ];

  const activityObj = activity.map((el) => ({
    username: el.causer_user ? el.causer_user.name : "Guest",
    type: "update",
    event: events[el.properties[1]],
    timestamp: `on ${moment(el.created_at).format("LLL")}`,
    createdat: moment(el.created_at).unix(),
    timelineIcon: icons[el.properties[1]],
  }));

  const commentsObj = comments.map((el) => ({
    username: el.user ? el.user.name : "Guest",
    event: "added a comment",
    timestamp: `on ${moment(el.created_at).format("LLL")}`,
    createdat: moment(el.created_at).unix(),
    timelineIcon: (
      <EuiAvatar size="l" name={el.user ? el.user.name : "Guest"} />
    ),
    children: <EuiText textAlign="left">{el.text}</EuiText>,
  }));

  const fullComments = _(activityObj)
    .concat(commentsObj)
    .orderBy("createdat", "desc")
    .value();

  return (
    <EuiFlexGroup
      gutterSize="none"
      responsive={false}
      alignItems="center"
      justifyContent="spaceAround"
      direction="column"
      style={{
        width: "100%",
      }}
    >
      <EuiFlexItem style={{ width: "100%", alignItems: "center" }} grow>
        <EuiPanel
          paddingSize="none"
          style={{
            width: "100%",
            minWidth: 150,
            minHeight: 150,
            textAlign: "center",
            overflow: "hidden",
            maxWidth: 360,
            boxShadow:
              selectedElements.indexOf(id) > -1 &&
              "0px 0px 4px rgb(47, 82, 255)",
          }}
        >
          <div style={{ padding: 8 }}>
            <EuiFlexGroup
              gutterSize="none"
              justifyContent="spaceBetween"
              style={{ width: "100%" }}
            >
              <EuiFlexItem grow={false}>
                <EuiFlexGroup>
                  <EuiFlexItem>{typeBadges[type]}</EuiFlexItem>

                  {additional_settings &&
                    typeof additional_settings.link_type !== "undefined" && (
                      <EuiFlexItem>
                        <EuiBadge color="hollow">
                          {linkTypes[additional_settings.link_type].text}
                        </EuiBadge>
                      </EuiFlexItem>
                    )}
                </EuiFlexGroup>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>{statusBadges[status]}</EuiFlexItem>
            </EuiFlexGroup>
          </div>

          <div
            style={{ position: "relative", cursor: "pointer" }}
            onClick={() => {
              setSelectedElements((v) =>
                v.indexOf(id) === -1
                  ? [...v, id]
                  : _.filter(v, (el) => el !== id),
              );
            }}
          >
            {type === 0 && (
              <div
                style={{
                  width: "100%",
                  paddingBottom: "100%",
                  backgroundImage: `url(${payload})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                  backgroundColor: "#000",
                }}
              />
            )}

            {type === 1 && (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: "100%",
                  backgroundColor: "#000",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <video
                  controls={true}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                  }}
                >
                  <source src={payload} />
                </video>
              </div>
            )}

            {type > 1 && type < 5 && (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: "100%",
                }}
                onClick={(e) => {
                  if (isEditing) {
                    e.stopPropagation();
                  }
                }}
              >
                <EuiTextArea
                  disabled={!isEditing}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    maxWidth: "none",
                    color: "#fff",
                  }}
                  value={isEditing ? draftPayload : payload}
                  onChange={(e) => setDraftPayload(e.target.value)}
                ></EuiTextArea>
              </div>
            )}

            {(type === 5 || type === 6) && (
              <div
                style={{
                  width: "100%",
                  paddingBottom: "100%",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "#202128",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <EuiText size="xs">
                      {type === 5 && link ? link.link : payload.split("?")[0]}
                    </EuiText>

                    <EuiSpacer />

                    <EuiButton
                      isLoading={isLoading}
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          (type === 5 && link
                            ? link.link
                            : payload.split("?")[0]) + "?utm_source=test",
                          "_blank",
                        );
                      }}
                    >
                      Go to link
                    </EuiButton>
                  </div>
                </div>
              </div>
            )}
          </div>

          <EuiSpacer size="l" />

          {fullComments && (
            <>
              <div style={{ marginLeft: 16, marginRight: 16 }}>
                <EuiAccordion id={`comment${id}`} buttonContent="Timeline">
                  <EuiSpacer size="m" />

                  <EuiCommentList comments={fullComments} />
                </EuiAccordion>
              </div>

              <EuiSpacer />
            </>
          )}
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Card;
