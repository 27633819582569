import React from "react";
import spinupLogo from "../../../assets/images/logo.svg";

const Logo = ({ image }) => {
  return (
    <a
      href="/"
      className="euiHeaderLogo home-logo"
      style={{
        alignItems: "center",
        height: 47.5,
        width: "100%",
        justifyContent: "center",
      }}
    >
      <img
        className="euiIcon euiIcon--large euiHeaderLogo__icon"
        style={{ width: "auto" }}
        src={image || spinupLogo}
        aria-label="Go to home page"
        alt="Spinup Logo"
      />
    </a>
  );
};

export default Logo;
