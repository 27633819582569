import React, { useState, useEffect } from "react";

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiAvatar,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldNumber,
  EuiSpacer,
  EuiTitle,
  EuiPopover,
  EuiFieldPassword,
  EuiIcon,
} from "@elastic/eui";
import useAuth from "../../hooks/auth";
import PageWrapper from "../../components/_layout/page-wrapper";
import useToast from "../../hooks/toast";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { strengthPasswordRegEx } from "../../constants";

const ProfilePage = () => {
  const { user, passwordUpdate, get2FASecret, activate2FA } = useAuth();
  const [qrCode, setQrCode] = useState(null);
  const [otpCode, setOtpCode] = useState(null);
  const [isOtpErrored, setIsOtpErrored] = useState(false);
  const [isOtpSubmitted, setIsOtpSubmitted] = useState(false);
  const [isSubmittingOtp, setIsSubmittingOtp] = useState(false);
  const [passwordPopover, setPasswordPopover] = useState(false);
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { register, errors, watch, handleSubmit } = useForm();
  const [backendErrors, setBackendErrors] = useState();

  useEffect(() => {
    const init = async () => {
      try {
        const response = await get2FASecret();

        setQrCode(response.data.qr_code);
      } catch (e) {
        console.error(e);
      }
    };

    init();
  }, [get2FASecret]);

  const onSubmit = (data) => {
    setIsLoading(true);
    setBackendErrors();
    passwordUpdate({
      credentials: data,
      onSuccess: () => {
        setIsLoading(false);
        addToast({
          title: `Password modificata con successo!`,
          iconType: "check",
          color: "success",
        });
      },
      onError: (e) => {
        setIsLoading(false);
        setBackendErrors(_.map(e.response.data.errors, (error) => error));
      },
    });
  };

  return (
    <PageWrapper>
      <EuiFlexGroup direction="column" gutterSize="xl">
        <EuiFlexItem>
          <EuiFlexGroup direction="column" alignItems="center">
            <EuiFlexItem style={{ width: 400 }}>
              <EuiFlexGroup direction="column" alignItems="center">
                <EuiFlexItem grow={false}>
                  <EuiAvatar size="xl" name={user.name} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiTitle>
                    <h1>
                      <EuiIcon type="user" size="l" />
                      {"  "}
                      {user.name}
                    </h1>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiTitle>
                    <h1>
                      <EuiIcon type="email" size="l" />
                      {"  "}
                      {user.email}
                    </h1>
                  </EuiTitle>
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <EuiPopover
                    id="passwordPopover"
                    ownFocus
                    button={
                      <EuiButton
                        size="l"
                        onClick={() => setPasswordPopover(!passwordPopover)}
                      >
                        Modifica password
                      </EuiButton>
                    }
                    isOpen={passwordPopover}
                    closePopover={() => setPasswordPopover(false)}
                  >
                    <div style={{ width: "450px" }}>
                      <EuiForm
                        component="form"
                        isInvalid={backendErrors}
                        error={backendErrors}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <EuiSpacer />
                        <EuiFormRow
                          label="Vecchia password"
                          fullWidth
                          error={
                            errors.old_password && ["Questo campo è richiesto"]
                          }
                          isInvalid={errors.old_password}
                        >
                          <EuiFieldPassword
                            fullWidth
                            type="dual"
                            name="old_password"
                            isInvalid={errors.old_password}
                            inputRef={register({
                              required: true,
                            })}
                          />
                        </EuiFormRow>

                        <EuiSpacer />

                        <EuiFormRow
                          label="Nuova password"
                          fullWidth
                          isInvalid={errors.new_password}
                          error={
                            errors.old_password && ["Questo campo è richiesto"]
                          }
                        >
                          <EuiFieldPassword
                            type="dual"
                            fullWidth
                            isInvalid={errors.new_password}
                            name="new_password"
                            inputRef={register({
                              required: true,
                              minLength: {
                                value: 8,
                                message:
                                  "La password deve contenere almeno 8 caratteri",
                              },
                              pattern: {
                                value: strengthPasswordRegEx,
                                message:
                                  "La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola,  un numero e un carattere speciale",
                              },
                            })}
                          />
                        </EuiFormRow>

                        <EuiSpacer />

                        <EuiFormRow
                          label="Conferma nuova password"
                          isInvalid={
                            errors.new_password_confirmation && [
                              "Questo campo è richiesto",
                            ]
                          }
                          error={
                            (errors.new_password_confirmation && [
                              errors.new_password_confirmation.message,
                            ]) || ["La conferma password deve combaciare"]
                          }
                          fullWidth
                        >
                          <EuiFieldPassword
                            type="dual"
                            fullWidth
                            name="new_password_confirmation"
                            inputRef={register({
                              required: "Questo campo è richiesto",
                              minLength: {
                                value: 8,
                                message:
                                  "La password deve contenere almeno 8 caratteri",
                              },
                              pattern: {
                                value: strengthPasswordRegEx,
                                message:
                                  "La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola,  un numero e un carattere speciale",
                              },
                              validate: (value) =>
                                value === watch("new_password"),
                            })}
                          />
                        </EuiFormRow>

                        <EuiSpacer />

                        <EuiButton
                          fullWidth
                          type="submit"
                          fill
                          isLoading={isLoading}
                        >
                          Conferma
                        </EuiButton>
                      </EuiForm>
                    </div>
                  </EuiPopover>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      {(user.google2fa_activated_at || isOtpSubmitted) && (
        <EuiFlexGroup
          style={{ marginTop: 48 }}
          direction="column"
          alignItems="center"
          gutterSize="xl"
        >
          <EuiFlexItem>La two-factor authentication è abilitata.</EuiFlexItem>
        </EuiFlexGroup>
      )}

      {!user.google2fa_activated_at && !isOtpSubmitted && qrCode && (
        <EuiFlexGroup
          style={{ marginTop: 48 }}
          direction="column"
          alignItems="center"
          gutterSize="xl"
        >
          <EuiFlexItem>
            <EuiTitle>
              <h3>Two-Factor Authentication</h3>
            </EuiTitle>
          </EuiFlexItem>

          <EuiFlexItem>
            <div dangerouslySetInnerHTML={{ __html: qrCode }} />
          </EuiFlexItem>

          <EuiFlexItem>
            <form
              onSubmit={async (e) => {
                e.preventDefault();

                setIsOtpErrored(false);
                setIsSubmittingOtp(true);

                try {
                  await activate2FA({ otp: otpCode });
                  setIsSubmittingOtp(false);
                  setIsOtpSubmitted(true);
                } catch (e) {
                  setIsOtpErrored(true);
                  setIsSubmittingOtp(false);
                }
              }}
            >
              <EuiFormRow
                isInvalid={isOtpErrored}
                error="Il codice inserito non è valido"
                label="Inserisci l'OTP generato con Google Authenticator"
              >
                <EuiFieldNumber
                  placeholder="OTP"
                  value={otpCode}
                  onChange={(e) => setOtpCode(e.target.value)}
                />
              </EuiFormRow>

              <EuiFormRow>
                <EuiButton
                  isLoading={isSubmittingOtp}
                  fullWidth
                  type="submit"
                  fill
                >
                  Attiva
                </EuiButton>
              </EuiFormRow>
            </form>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </PageWrapper>
  );
};

export default ProfilePage;
