import React, { useMemo } from "react";
import {
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiTitle,
} from "@elastic/eui";
import HeaderProfile from "./profile";
import { matchPath, useLocation } from "react-router-dom";
import { brainSections } from "../../../constants/brain-sections";
import routes from "../../../routes";

export default function Header({ openMenu }) {
  return (
    <EuiHeader
      sections={[
        {
          items: [
            <EuiHeaderSectionItemButton
              aria-label="Open nav"
              onClick={() => openMenu()}
            >
              <EuiIcon type="menu" href="#" size="m" />
            </EuiHeaderSectionItemButton>,
            <PageLogo />,
          ],
          borders: "none",
        },
        {
          items: [<HeaderProfile />],
          borders: "none",
        },
      ]}
    />
  );
}

const defaultSoftware = {
  id: "brain",
  name: "Brain",
  icon: "logoBeats",
  href: "/",
};

function PageLogo() {
  const location = useLocation();
  const sectionId = location.pathname.split("/")[1];

  const selectedSection = useMemo(
    () => brainSections.find((s) => s.path === sectionId),
    [sectionId],
  );

  const currentRoute = useMemo(() => {
    return routes.find((r) => {
      const props = { path: r.path, exact: r.exact };
      return matchPath(location.pathname, props);
    });
  }, [location.pathname]);

  const section = selectedSection ?? defaultSoftware;
  const url = currentRoute?.headerUrl ?? section.href;

  return (
    <EuiHeaderLogo
      iconType={section.icon}
      href={url}
      aria-label="Go to home page"
    >
      <EuiTitle size="s">
        <h1>{section.name}</h1>
      </EuiTitle>
    </EuiHeaderLogo>
  );
}
